import { createStore } from 'vuex'

export default createStore({
    state: {
        form: {}
    },
    getters: {
    },
    mutations: {
        setForm(state, data){
          state.form = data
        }
    },
    actions: {
    },
    modules: {
    }
})
