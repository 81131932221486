import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

const app = createApp(App); 

import 'normalize.css'  //引入重置样式css 
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import Vant from 'vant';
import 'vant/lib/index.css';

// import VConsole from 'vconsole'
// const vConsole = new VConsole();
// window.vConsole = vConsole;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {  
  app.component(key, component);  
}

app.config.errorHandler=(err, vm, info)=>{
  console.error(err);
}

app.use(store).use(router).use(Vant).use(ElementPlus, { locale: zhCn }).mount('#app')
